import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlogPostPreview from "../components/blog-post-preview";
import { BlogIndexNode } from "../types/queries";
import Pagnation from "../components/pagination";

function PaginatedBlogListing({ data, pageContext }): JSX.Element {
  const posts: BlogIndexNode[] = data.allMdx.nodes;
  pageContext.title = data.site.siteMetadata.blogIndexTitle;
  return (
    <Layout pageContext={pageContext}>
      {posts.map((post) => (
        <BlogPostPreview
          key={post.frontmatter.slug ?? post.slug}
          post={post}
        />
      ))}
      {pageContext.totalPages > 1 && 
        <Pagnation
        pageOne="/blog"
        otherPageTemplate="/blog/"
        currentPage={pageContext.currentPage}
        totalPages={pageContext.totalPages}
        pageSize={pageContext.limit}
        navLabel="Blog Index Navigation"
        />
      }
      
    </Layout>
  );
}

export default PaginatedBlogListing;

export const query = graphql`
  query blogListing($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        blogIndexTitle
      }
    }
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        slug
        frontmatter {
          title
          description
          slug
        }
      }
    }
  }
`;
