import * as React from "react";
import Link from "./link";
import paginate from "jw-paginate";

const maxNumberOfItems: number = 5;

export default function Pagnation({
  pageOne,
  otherPageTemplate,
  currentPage,
  totalPages,
  pageSize,
  navLabel,
}): JSX.Element {
  const enumeratedPages = getPages(
    currentPage,
    totalPages,
    maxNumberOfItems,
    pageSize
  );
  const previousPage: number = currentPage - 1;
  const nextPage: number = currentPage + 1;
  const previousIsEnabled: boolean = previousPage > 0;
  const nextIsEnabled: boolean = currentPage < enumeratedPages.totalPages;

  return (
    <nav aria-label={navLabel}>
      <ul className="pagination">
        {getNavItem({
          active: false,
          enabled: previousIsEnabled,
          text: "Previous",
          url: getPageUrl(pageOne, otherPageTemplate, previousPage),
        })}
        {enumeratedPages.pages.map((page) =>
          getNavItem({
            active: page === currentPage,
            enabled: page !== currentPage,
            text: `${page}`,
            url: getPageUrl(pageOne, otherPageTemplate, page),
          })
        )}
        {getNavItem({
          active: false,
          enabled: nextIsEnabled,
          text: "Next",
          url: getPageUrl(pageOne, otherPageTemplate, nextPage),
        })}
      </ul>
    </nav>
  );
}

function getPages(
  currentPage: number,
  totalPages: number,
  maxPages: number,
  pageSize: number
) {
  return paginate(totalPages, currentPage, pageSize, maxPages);
}

function getNavItem({ active, enabled, text, url }): JSX.Element {
  const classState: string = active ? "active" : !enabled ? "disabled" : "";
  return (
    <li className={`page-item ${classState}`} key={url}>
      {!enabled && <span className="page-link">{text}</span>}
      {!!enabled && (
        <Link href={url} className="page-link">
          {text}
        </Link>
      )}
    </li>
  );
}

function getPageUrl(pageOne: string, otherPages: string, page: number): string {
  return page == 1 ? pageOne : `${otherPages}${page}`;
}
