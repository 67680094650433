import { BlogIndexNode } from "../types/queries";

export default abstract class BlogPostUrlHelper {
  /**
   * Provides a consistent means to generating blog post URLs
   * so that the logic isn't repeated needlessly.
   *
   * @param slug The bit that goes at the end of the URL.
   * @returns A sitewide-consistent URL for the blog post.
   */
  public static getBlogPostUrlFromSlug(slug: string): string {
    return `/blog/${slug}`;
  }

  public static GetBlogPostUrlFromBlogIndexNode(node: BlogIndexNode) {
    return BlogPostUrlHelper.getBlogPostUrlFromSlug(node.frontmatter.slug ?? node.slug);
  }
}
