import * as React from "react";
import BlogPostUrlHelper from "../helpers/blog-post-url-helper";
import { BlogIndexNode } from "../types/queries";
import Link from "./link";

function BlogPostPreview(
  content: { post: BlogIndexNode }): JSX.Element {
  const blogPostUrl: string = BlogPostUrlHelper.GetBlogPostUrlFromBlogIndexNode(content.post);
  return (
    <div className="col-xxl-4 col-lg-6 mb-5">
      <div className="card h-100">
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">{content.post.frontmatter.title}</h5>
          <p className="card-text my-auto">{content.post.frontmatter.description}</p>
          <Link href={blogPostUrl} className="btn btn-primary mt-5">
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogPostPreview;